import React from "react";
import OffersSection from "@common/CommonSections/OffersSection/OffersSection"
import { 
    SERVICES,
} from "../../constants";
import { 
    StyledServicesWrapper,
    StyledServicesTitle
} from "./styled";

const Services = () => {
    return (
        <StyledServicesWrapper>
            <StyledServicesTitle>Our services</StyledServicesTitle>
            <OffersSection 
              configuration={SERVICES} 
              hasTitle
            />
        </StyledServicesWrapper>
    )
};

export default Services;