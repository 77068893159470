import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants"

export const StyledServicesWrapper = styled.div` 
    margin: 7.5rem 0;
`;

export const StyledServicesTitle = styled.h2`
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    letter-spacing: 0.8px;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.438rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 2rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.5rem;
    }

`;
