import React from "react"
import OffersSection from "@common/CommonSections/OffersSection/OffersSection"
import { SERVICES, SERVICES_SECTION_TITLE } from "../../constants"
import { StyledMarginWrapper } from "../../common-styles"
import { StyledServicesWrapper, StyledServicesTitle } from "./styled"

const Services = () => {
  return (
    <StyledMarginWrapper>
      <StyledServicesWrapper>
        <StyledServicesTitle>
            {SERVICES_SECTION_TITLE}
        </StyledServicesTitle>
        <OffersSection configuration={SERVICES} hasTitle />
      </StyledServicesWrapper>
    </StyledMarginWrapper>
  )
}

export default Services
